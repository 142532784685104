import React from "react";
import Img from "gatsby-image"
import { graphql, Link } from 'gatsby';
import Layout from "../layouts/layout";

const SolutionsServicesChild = ({ data: { hero, pageData, imageOne, imageTwo, imageThree, imageFour, imageFive, projects } }) => {

return (
<Layout hero={hero}>

<div className="container mx-auto mb-16">
    <div className="sm:flex items-center justify-between">
        <div className="flex-1 px-4 mb-4 sm:mb-0 self-start">
            <Img className="mb-2 mr-12" alt="" fluid={imageOne.fluid} />
        </div>
        <div className="flex-1 px-4">

            <div className="mb-6">
                <div className="text-3xl sm:text-5xl font-aktiv font-bold dark-blue leading-tight"
                   dangerouslySetInnerHTML={{ __html: pageData.promoOneHeading.childMarkdownRemark.html }}
                 />
            </div>                    


            <div className="nested-html"
                   dangerouslySetInnerHTML={{ __html: pageData.promoOneText.childMarkdownRemark.html }}
            />                    

        </div>
    </div>
</div>

{pageData.promoTwoText &&
<div className="container mx-auto mb-16">
    <div className="sm:flex items-center justify-between">
        <div className="flex-1 px-4 mb-4 sm:mb-0 self-start">
            <Img className="mb-2" alt="" fluid={imageTwo.fluid} />
        </div>
        <div className="flex-1 px-4 order-first">
            <div className="mb-6">
                <h2 className="text-3xl sm:text-5xl font-aktiv font-bold dark-blue leading-tight">Engineering &</h2>
                <h2 className="text-3xl sm:text-5xl font-aktiv font-bold dark-blue leading-tight">Construction Expertise</h2>
            </div>
            <div className="nested-html"
                   dangerouslySetInnerHTML={{ __html: pageData.promoTwoText.childMarkdownRemark.html }}
            />                    
            
        </div>
    </div>
</div>
}


<div className="container mx-auto mb-16">
    <div className="sm:flex justify-between">
        <div className="flex-1 px-4 text-right mb-4 sm:mb-0 self-start">
            <Img className="" alt="" fluid={imageFive.fluid} />
        </div>
        <div className="flex-1 px-4">
            <div className="mb-6">
                <h2 className="text-1-5 font-aktiv font-bold dark-blue leading-tight">Building Expertise</h2>
            </div>

            <div className="sm:flex pt-10 mb-6 border-t">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">2 million</h5>
                    <p className="soft-gray text-sm">SQM of Malls and Shopping Centers</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">30</h5>
                    <p className="soft-gray text-sm">Skyscrapers</p>
                </div>
            </div>
            <div className="sm:flex pb-10 border-b">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">5,000</h5>
                    <p className="soft-gray text-sm">Hotel Rooms</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">20</h5>
                    <p className="soft-gray text-sm">Educational Complexes</p>
                </div>
            </div>

        </div>
    </div>
</div>

<div className="container mx-auto mb-16">
    <div className="sm:flex items-center justify-between">
        <div className="flex-1 px-4 text-right mb-4 sm:mb-0 self-stretch">
            <Img className="h-full" alt="" fluid={imageThree.fluid} />
        </div>
        <div className="flex-1 px-4 order-first">
            <div className="mb-6">
                <h2 className="text-1-5 font-aktiv font-bold dark-blue leading-tight">Utilities Expertise</h2>
            </div>

            <div className="sm:flex pt-10 mb-6 border-t">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">70 KM</h5>
                    <p className="soft-gray text-sm">of Pressurized Systems</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">70 KM</h5>
                    <p className="soft-gray text-sm">of Gravity Systems</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">30 Million</h5>
                    <p className="soft-gray text-xs">Imperial Gallons per Day (MIGD) of Desalination and Sewage Treatment Plants</p>
                </div>

            </div>
            <div className="sm:flex pb-10 border-b">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">250 KM</h5>
                    <p className="soft-gray text-sm">of High, Medium and Low Voltage Power Distribution</p>
                </div>
                <div className="flex-1 text-center md:pr-12">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">100 KM</h5>
                    <p className="soft-gray text-sm">of Fiber Optics</p>
                </div>
            </div>

        </div>
    </div>
</div>


<div className="container mx-auto mb-16">
    <div className="sm:flex items-center justify-between">
        <div className="flex-1 px-4 text-right mb-4 sm:mb-0 self-start">
            <Img className="" alt="" fluid={imageFour.fluid} />
        </div>
        <div className="flex-1 px-4">
            <div className="mb-6">
                <h2 className="text-1-5 font-aktiv font-bold dark-blue leading-tight">Transportation Expertise</h2>
            </div>

            <div className="sm:flex pt-10 mb-6 border-t">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">900 M</h5>
                    <p className="soft-gray text-sm">Length lane bridges</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">77 KM</h5>
                    <p className="soft-gray text-sm">of Street Lighting</p>
                </div>
            </div>
            <div className="sm:flex pb-10 border-b">
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">40 KM</h5>
                    <p className="soft-gray text-sm">of Roads</p>
                </div>
                <div className="flex-1 text-center">
                    <p className="soft-gray text-xs">More than</p>
                    <h5 className="text-4xl blue font-bold">6 KM</h5>
                    <p className="soft-gray text-sm">of Utility Tunnels & Microtunneling</p>
                </div>
            </div>

        </div>
    </div>
</div>

<div className="container mx-auto px-4 mb-16">
    <div className="mb-6">
        <h2 className="text-2xl font-aktiv font-bold dark-blue leading-tight">Case Studies</h2>
    </div>
    <div className="sm:flex items-center items-stretch">
             {pageData.caseStudies.map((caseStudy) => (
                <div className="flex flex-col mb-4 sm:mb-0 sm:w-1/4 border-shadow mr-6 sm:ml-0" key={caseStudy.id}>
                        <Link to={`/case-study/${caseStudy.slug}`}>
                            <Img className="sm:h-40 lg:h-64 object-cover w-full" alt={caseStudy.title} fluid={caseStudy.previewImage.fluid} />
                            <div className="p-4">
                                <h3 className="text-gray-800 text-base font-bold">{caseStudy.title}</h3>
                            </div>
                    </Link>
                </div>
            ))}
    </div>

</div>

</Layout>
    )
};

export const query = graphql`

query ServicesQuery($slug: String!) {
    hero: contentfulSolutionsServicesSubsections(slug: {eq: $slug}) {
        subsectionName
        heroBackgroundImage {
             resize (width: 1440, quality: 70) {
                src 
            }
        }
        heroHeading {
          childMarkdownRemark {
              html
          }
        }
        heroText {
            childMarkdownRemark {
                html
            }
        }
    }
    pageData: contentfulSolutionsServicesSubsections(slug: {eq: $slug}) {
        promoOneText {
            childMarkdownRemark {
               html
            }
        }
        promoOneHeading {
            childMarkdownRemark {
               html
            }
        }
        promoTwoText {
            childMarkdownRemark {
               html
            }
        }
        caseStudies { 
            id
            slug
            title
            pdf {
                file    {
                    url
                }
            }
            previewImage {
                 fluid {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
   }
   imageOne: contentfulAsset(title: {eq: "solutions-services-solving-complex"}) {
         fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
   }
   imageTwo: contentfulAsset(title: {eq: "solutions-services-damac-tower"}) {
         fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
   }
   imageThree: contentfulAsset(title: {eq: "solutions-services-utilities"}) {
         fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
   }
   imageFour: contentfulAsset(title: {eq: "solutions-services-transportation"}) {
         fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
   }
   imageFive: contentfulAsset(title: {eq: "solutions-services-building-expertise"}) {
         fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
   }
  }
`;

export default SolutionsServicesChild;
